import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import './DataTables.css';

const DataTables = ({ nodes, links, activeTab, onTabChange, onFilterChange, threshold }) => {
  const [nodeFilters, setNodeFilters] = useState({});
  const [linkFilters, setLinkFilters] = useState({});

  useEffect(() => {
    setLinkFilters(prev => ({ ...prev, infoTransfer: threshold }));
  }, [threshold]);

  const handleFilterChange = (table, column, value) => {
    if (table === 'nodes') {
      setNodeFilters(prev => ({ ...prev, [column]: value }));
    } else {
      setLinkFilters(prev => ({ ...prev, [column]: value }));
    }
  };

  useEffect(() => {
    onFilterChange({ nodes: nodeFilters, links: linkFilters });
  }, [nodeFilters, linkFilters, onFilterChange]);

  const filteredNodes = useMemo(() => {
    return nodes.filter(node => {
      return Object.entries(nodeFilters).every(([key, value]) => {
        if (!value) return true;
        const nodeValue = String(node[key]).toLowerCase();
        return nodeValue.includes(value.toLowerCase());
      });
    });
  }, [nodes, nodeFilters]);

  const filteredLinks = useMemo(() => {
    return links.filter(link => {
      return Object.entries(linkFilters).every(([key, value]) => {
        if (!value) return true;
        if (key === 'infoTransfer') {
          return link[key] >= Number(value);
        }
        const linkValue = String(link[key]).toLowerCase();
        return linkValue.includes(value.toLowerCase());
      });
    }).filter(link => link.infoTransfer > threshold);
  }, [links, linkFilters, threshold]);

  const renderFilter = (table, column) => {
    const prettyNames = {
      id: 'ID',
      name: 'Name',
      price: 'Price',
      volume: 'Volume',
      totalValue: 'Total Value',
      source: 'Source',
      target: 'Target',
      infoTransfer: 'Info Transfer'
    };

    return (
      <input
        type="text"
        placeholder={`Filter ${prettyNames[column]}`}
        onChange={(e) => handleFilterChange(table, column, e.target.value)}
        value={(table === 'nodes' ? nodeFilters : linkFilters)[column] || ''}
      />
    );
  };

  return (
    <div className="data-tables">
      <div className="tabs">
        <button
          className={activeTab === 'nodes' ? 'active' : ''}
          onClick={() => onTabChange('nodes')}
        >
          Nodes
        </button>
        <button
          className={activeTab === 'edges' ? 'active' : ''}
          onClick={() => onTabChange('edges')}
        >
          Edges
        </button>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {activeTab === 'nodes' ? (
                <>
                  <th>{renderFilter('nodes', 'id')}ID</th>
                  <th>{renderFilter('nodes', 'name')}Name</th>
                  <th>{renderFilter('nodes', 'price')}Price</th>
                  <th>{renderFilter('nodes', 'volume')}Volume</th>
                  <th>{renderFilter('nodes', 'totalValue')}Total Value</th>
                </>
              ) : (
                <>
                  <th>{renderFilter('links', 'source')}Source</th>
                  <th>{renderFilter('links', 'target')}Target</th>
                  <th>{renderFilter('links', 'infoTransfer')}Info Transfer</th>
                </>
              )}
            </tr>
          </thead>
        </table>
        <div className="table-body-container">
          <table>
            <tbody>
              {activeTab === 'nodes'
                ? filteredNodes.map((node) => (
                    <tr key={node.id}>
                      <td>{node.id}</td>
                      <td>{node.name}</td>
                      <td>${node.price?.toFixed(2) || 'N/A'}</td>
                      <td>{node.volume?.toLocaleString() || 'N/A'}</td>
                      <td>{node.totalValue ? `$${node.totalValue.toLocaleString()}` : 'N/A'}</td>
                    </tr>
                  ))
                : filteredLinks.map((link, index) => (
                    <tr key={index}>
                      <td>{link.source.id || link.source}</td>
                      <td>{link.target.id || link.target}</td>
                      <td>{link.infoTransfer?.toFixed(4) || 'N/A'}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

DataTables.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    volume: PropTypes.number.isRequired,
    totalValue: PropTypes.number.isRequired,
  })).isRequired,
  links: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  threshold: PropTypes.number.isRequired,
};

export default DataTables;