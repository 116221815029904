import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import "./App.css";
import ConnectivityDashboard from "./ConnectivityDashboard";
import Modal from "react-modal";

Modal.setAppElement("#root"); // or whatever your root element id is

function App() {
  useEffect(() => {
    // Track page view
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // const handleButtonClick = () => {
  //   // Track custom event
  //   ReactGA.event({
  //     category: "User",
  //     action: "Clicked Button",
  //   });
  // };

  return (
    <div className="App">
      <div className="header">
        <h1>Connectivity Dashboard</h1>
      </div>
      <div className="dashboard-container">
        <ConnectivityDashboard />
      </div>
      {/* <button onClick={handleButtonClick}>Click me</button> */}
    </div>
  );
}

export default App;
