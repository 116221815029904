import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import Modal from 'react-modal';

const D3ConnectivityGraph = ({ data, threshold, layout }) => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (!data || !svgRef.current || dimensions.width === 0 || dimensions.height === 0) return;

    const { width, height } = dimensions;
    const nodeSize = 60;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    svg.selectAll("*").remove();

    const g = svg.append("g");

    const zoom = d3
      .zoom()
      .scaleExtent([0.1, 4])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });

    svg.call(zoom);

    svg
      .append("defs")
      .selectAll("marker")
      .data(["end"])
      .enter()
      .append("marker")
      .attr("id", String)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 25)
      .attr("refY", 0)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-5L10,0L0,5")
      .attr("fill", "#999");

    // Create node objects and a map for quick lookup
    const nodes = data.nodes.map((node) => ({
      ...node,
      locked: node.locked || false,
    }));
    const nodeById = new Map(nodes.map((node) => [node.id, node]));

    // Update links to reference node objects, filtering out invalid links
    const links = data.links
      .filter((link) => nodeById.has(link.source) && nodeById.has(link.target))
      .map((link) => ({
        ...link,
        source: nodeById.get(link.source),
        target: nodeById.get(link.target),
      }));

    // Create a color scale
    const colorScale = d3.scaleSequential(d3.interpolateBlues)
      .domain([0, d3.max(nodes, d => d.totalValue)]);

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3
          .forceLink(links)
          .id((d) => d.id)
          .distance(Math.min(width, height) * 0.2)
      )
      .force("charge", d3.forceManyBody().strength(-1000))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collision", d3.forceCollide().radius(nodeSize));

    const link = g
      .append("g")
      .selectAll("path")
      .data(links)
      .enter()
      .append("path")
      .attr("stroke", "#999")
      .attr("stroke-width", (d) => d.value * 2)
      .attr("fill", "none")
      .attr("marker-end", "url(#end)")
      .on("contextmenu", (event, d) =>
        handleEdgeContextMenu(event, d, svg)
      );

    link
      .append("title")
      .text((d) => `Info Transfer: ${d.infoTransfer.toFixed(2)}`);

    const node = g
      .append("g")
      .selectAll("g")
      .data(nodes)
      .enter()
      .append("g")
      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      )
      .on("contextmenu", (event, d) =>
        handleNodeContextMenu(event, d, svg, simulation)
      );

    node
      .append("rect")
      .attr("width", nodeSize)
      .attr("height", nodeSize)
      .attr("fill", d => colorScale(d.totalValue))  // Set color based on total value
      .attr("stroke", "black")
      .attr("x", -nodeSize / 2)
      .attr("y", -nodeSize / 2);

    node
      .append("text")
      .text((d) => d.id)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", "12px");

    // Add lock icon
    node
      .append("text")
      .attr("class", "lock-icon")
      .text((d) => (d.locked ? "🔒" : "🔓"))
      .attr("x", nodeSize / 2 - 15)
      .attr("y", -nodeSize / 2 + 15)
      .attr("font-size", "12px")
      .style("cursor", "pointer")
      .on("click", (event, d) => {
        d.locked = !d.locked;
        d3.select(event.target).text(d.locked ? "🔒" : "🔓");
        if (!d.locked) {
          d.fx = null;
          d.fy = null;
        } else {
          d.fx = d.x;
          d.fy = d.y;
        }
        simulation.alpha(1).restart();
      });

    // Update the tooltip to include the total value
    node
      .append("title")
      .text(
        (d) =>
          `${d.name}\nPrice: $${d.price}\nVolume: ${d.volume.toLocaleString()}\nTotal Value: $${d.totalValue.toLocaleString()}`
      );

    function ticked() {
      link.attr("d", (d) => {
        const sourcePoint = calculateEdgePoint(d.source, d.target);
        const targetPoint = calculateEdgePoint(d.target, d.source);
        const midX = (sourcePoint.x + targetPoint.x) / 2;
        const midY = (sourcePoint.y + targetPoint.y) / 2;
        const dx = targetPoint.x - sourcePoint.x;
        const dy = targetPoint.y - sourcePoint.y;
        const normalX = -dy;
        const normalY = dx;
        const curvature = 0.2;
        const controlX = midX + normalX * curvature;
        const controlY = midY + normalY * curvature;
        return `M${sourcePoint.x},${sourcePoint.y} Q${controlX},${controlY} ${targetPoint.x},${targetPoint.y}`;
      });

      node.attr("transform", (d) => `translate(${d.x},${d.y})`);
    }

    simulation.on("tick", ticked);

    function calculateEdgePoint(source, target) {
      const dx = target.x - source.x;
      const dy = target.y - source.y;
      const angle = Math.atan2(dy, dx);

      // Calculate the point where the line intersects the square
      let x, y;
      const halfSize = nodeSize / 2;

      if (Math.abs(dx) > Math.abs(dy)) {
        // Intersects on left or right side
        x = dx > 0 ? halfSize : -halfSize;
        y = x * Math.tan(angle);
      } else {
        // Intersects on top or bottom side
        y = dy > 0 ? halfSize : -halfSize;
        x = y / Math.tan(angle);
      }

      // Ensure the point is within the square bounds
      x = Math.max(-halfSize, Math.min(halfSize, x));
      y = Math.max(-halfSize, Math.min(halfSize, y));

      return {
        x: source.x + x,
        y: source.y + y,
      };
    }

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      if (!d.locked) {
        d.fx = null;
        d.fy = null;
      }
    }

    function applyLayout() {
      switch (layout) {
        case "circular":
          applyCircularLayout(nodes);
          break;
        case "grid":
          applyGridLayout(nodes);
          break;
        case "hierarchical":
          applyHierarchicalLayout(nodes, links);
          break;
        default:
          // Force-directed layout
          nodes.forEach((node) => {
            if (!node.locked) {
              node.fx = null;
              node.fy = null;
            }
          });
      }

      // Update simulation forces
      simulation
        .force(
          "link",
          d3
            .forceLink(links)
            .id((d) => d.id)
            .distance(Math.min(width, height) * 0.2)
        )
        .force("charge", d3.forceManyBody().strength(-1000))
        .force("center", d3.forceCenter(width / 2, height / 2));

      // Restart simulation
      simulation.alpha(1).restart();
    }

    function applyCircularLayout(nodes) {
      const radius = Math.min(width, height) / 2 - 100;
      nodes.forEach((node, i) => {
        if (!node.locked) {
          const angle = (i / nodes.length) * 2 * Math.PI;
          node.x = node.fx = width / 2 + radius * Math.cos(angle);
          node.y = node.fy = height / 2 + radius * Math.sin(angle);
        }
      });
    }

    function applyGridLayout(nodes) {
      const cols = Math.ceil(Math.sqrt(nodes.length));
      const cellWidth = width / cols;
      const cellHeight = height / cols;
      nodes.forEach((node, i) => {
        if (!node.locked) {
          const col = i % cols;
          const row = Math.floor(i / cols);
          node.x = node.fx = col * cellWidth + cellWidth / 2;
          node.y = node.fy = row * cellHeight + cellHeight / 2;
        }
      });
    }

    function applyHierarchicalLayout(nodes, links) {
      // Create a map of node ids to their levels
      const nodeLevels = new Map();

      // Function to get or set a node's level
      function getNodeLevel(id, visited = new Set()) {
        if (nodeLevels.has(id)) return nodeLevels.get(id);
        if (visited.has(id)) return 0; // Handle cycles

        visited.add(id);
        const incomingLinks = links.filter((link) => link.target === id);

        if (incomingLinks.length === 0) {
          nodeLevels.set(id, 0);
          return 0;
        }

        const maxParentLevel = Math.max(
          ...incomingLinks.map((link) =>
            getNodeLevel(link.source, new Set(visited))
          )
        );
        const level = maxParentLevel + 1;
        nodeLevels.set(id, level);
        return level;
      }

      // Assign levels to all nodes
      nodes.forEach((node) => getNodeLevel(node.id));

      // Calculate the maximum level
      const maxLevel = Math.max(...nodeLevels.values());

      // Update node positions
      nodes.forEach((node) => {
        if (!node.locked) {
          const level = nodeLevels.get(node.id);
          const nodesAtLevel = nodes.filter(
            (n) => nodeLevels.get(n.id) === level
          );
          const index = nodesAtLevel.indexOf(node);

          node.x = node.fx = (width * (level + 1)) / (maxLevel + 2);
          node.y = node.fy = (height * (index + 1)) / (nodesAtLevel.length + 1);
        }
      });

      // Update simulation forces
      simulation
        .force(
          "link",
          d3
            .forceLink(links)
            .id((d) => d.id)
            .distance(100)
        )
        .force("charge", d3.forceManyBody().strength(-1000))
        .force("x", d3.forceX((d) => d.fx).strength(1))
        .force("y", d3.forceY((d) => d.fy).strength(1));
    }

    applyLayout();
    simulation.alpha(1).restart();

    function handleNodeContextMenu(event, d, svg, simulation) {
      event.preventDefault();

      d3.select(".context-menu").remove();

      const [mouseX, mouseY] = d3.pointer(event, svg.node());

      const contextMenu = svg
        .append("g")
        .attr("class", "context-menu")
        .attr("transform", `translate(${mouseX},${mouseY})`);

      contextMenu
        .append("rect")
        .attr("width", 120)
        .attr("height", 80)
        .attr("fill", "white")
        .attr("stroke", "black");

      contextMenu
        .append("text")
        .text("Node Info")
        .attr("x", 10)
        .attr("y", 25)
        .attr("fill", "black")
        .style("cursor", "pointer")
        .on("click", () => {
          contextMenu.remove();
          openNodeInfoModal(d, links, threshold);
        });

      contextMenu
        .append("text")
        .text(d.locked ? "Unlock Node" : "Lock Node")
        .attr("x", 10)
        .attr("y", 55)
        .attr("fill", "black")
        .style("cursor", "pointer")
        .on("click", () => {
          d.locked = !d.locked;
          if (!d.locked) {
            d.fx = null;
            d.fy = null;
          } else {
            d.fx = d.x;
            d.fy = d.y;
          }
          simulation.alpha(1).restart();
          contextMenu.remove();
          // Update the lock icon
          svg
            .selectAll("g")
            .filter(function (node) {
              return node && node.id === d.id;
            })
            .select(".lock-icon")
            .text(d.locked ? "🔒" : "🔓");
        });

      svg.on("click.context-menu", () => {
        d3.select(".context-menu").remove();
        svg.on("click.context-menu", null);
      });
    }

    function openNodeInfoModal(node, links, threshold) {
      const incomingConnections = links.filter(
        (link) => link.target.id === node.id && link.infoTransfer > threshold
      );
      const outgoingConnections = links.filter(
        (link) => link.source.id === node.id && link.infoTransfer > threshold
      );

      const content = (
        <div>
          <h2>{node.id} Information</h2>
          <p>Name: {node.name}</p>
          <p>Price: ${node.price}</p>
          <p>Volume: {node.volume.toLocaleString()}</p>
          <h3>Incoming Connections</h3>
          <ul>
            {incomingConnections.map((link) => (
              <li key={link.source.id}>
                From: {link.source.id}, Info Transfer:{" "}
                {link.infoTransfer.toFixed(4)}
              </li>
            ))}
          </ul>
          <h3>Outgoing Connections</h3>
          <ul>
            {outgoingConnections.map((link) => (
              <li key={link.target.id}>
                To: {link.target.id}, Info Transfer:{" "}
                {link.infoTransfer.toFixed(4)}
              </li>
            ))}
          </ul>
        </div>
      );

      setModalContent(content);
      setModalIsOpen(true);
    }
  }, [data, threshold, layout, dimensions]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <svg ref={svgRef}></svg>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Node Information"
      >
        {modalContent}
        <button onClick={() => setModalIsOpen(false)}>Close</button>
      </Modal>
    </div>
  );
};

D3ConnectivityGraph.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      volume: PropTypes.number.isRequired,
      totalValue: PropTypes.number.isRequired,
    })).isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
      source: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      infoTransfer: PropTypes.number.isRequired,
    })).isRequired,
  }).isRequired,
  threshold: PropTypes.number.isRequired,
  layout: PropTypes.string.isRequired,
};

export default D3ConnectivityGraph;

function handleEdgeContextMenu(event, d, svg) {
  event.preventDefault();

  d3.select(".context-menu").remove();

  const [mouseX, mouseY] = d3.pointer(event, svg.node());

  const contextMenu = svg
    .append("g")
    .attr("class", "context-menu")
    .attr("transform", `translate(${mouseX},${mouseY})`);

  contextMenu
    .append("rect")
    .attr("width", 120)
    .attr("height", 40)
    .attr("fill", "white")
    .attr("stroke", "black");

  contextMenu
    .append("text")
    .text("Edge Info")
    .attr("x", 10)
    .attr("y", 25)
    .attr("fill", "black")
    .on("click", () => {
      console.log("Edge Info:", d);
      contextMenu.remove();
    });

  svg.on("click.context-menu", () => {
    d3.select(".context-menu").remove();
    svg.on("click.context-menu", null);
  });
}
