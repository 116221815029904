export const graphData = {
  nodes: [
    { id: "AAPL", name: "Apple", price: 150.25, volume: 1000000 },
    { id: "GOOGL", name: "Google", price: 2750.8, volume: 500000 },
    { id: "MSFT", name: "Microsoft", price: 310.75, volume: 750000 },
    { id: "AMZN", name: "Amazon", price: 3380.5, volume: 600000 },
  ],
  links: [
    { source: "AAPL", target: "GOOGL", value: 0.7, infoTransfer: 0.65 },
    { source: "GOOGL", target: "AAPL", value: 0.65, infoTransfer: 0.6 },
    { source: "AAPL", target: "MSFT", value: 0.6, infoTransfer: 0.55 },
    { source: "MSFT", target: "AAPL", value: 0.55, infoTransfer: 0.5 },
    { source: "GOOGL", target: "AMZN", value: 0.5, infoTransfer: 0.45 },
    { source: "AMZN", target: "GOOGL", value: 0.45, infoTransfer: 0.4 },
    { source: "MSFT", target: "AMZN", value: 0.4, infoTransfer: 0.35 },
    { source: "AMZN", target: "MSFT", value: 0.35, infoTransfer: 0.3 },
    { source: "GOOGL", target: "MSFT", value: 0.55, infoTransfer: 0.5 },
    { source: "MSFT", target: "GOOGL", value: 0.5, infoTransfer: 0.45 },
    { source: "AAPL", target: "AMZN", value: 0.45, infoTransfer: 0.4 },
    { source: "AMZN", target: "AAPL", value: 0.4, infoTransfer: 0.35 },
  ],
};