import React, { useState, useCallback, useMemo, useEffect } from "react";
import { graphData } from "./data/graphData";
import D3ConnectivityGraph from "./D3ConnectivityGraph";
import DataTables from './DataTables';

const ConnectivityDashboard = () => {

  const [data, setData] = useState({ nodes: [], links: [] });
  const [threshold, setThreshold] = useState(0.5);
  const [layout, setLayout] = useState('force');
  const [activeTab, setActiveTab] = useState('nodes');
  const [filters, setFilters] = useState({ nodes: {}, links: {} });

  const handleFilterChange = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const filteredData = useMemo(() => {
    const filteredNodes = data.nodes.filter(node => {
      return Object.entries(filters.nodes).every(([key, value]) => {
        if (!value) return true;
        const nodeValue = String(node[key]).toLowerCase();
        return nodeValue.includes(value.toLowerCase());
      });
    });

    const filteredLinks = data.links.filter(link => {
      return Object.entries(filters.links).every(([key, value]) => {
        if (!value) return true;
        if (key === 'infoTransfer') {
          return link[key] >= Number(value);
        }
        const linkValue = String(link[key]).toLowerCase();
        return linkValue.includes(value.toLowerCase());
      });
    });

    return { nodes: filteredNodes, links: filteredLinks };
  }, [data, filters]);

  useEffect(() => {
    // Simulate API call with setTimeout
    const fetchData = () => {
      setTimeout(() => {
        const sampleData = graphData;
        // Calculate total value for each node
        const nodesWithTotalValue = sampleData.nodes.map(node => ({
          ...node,
          totalValue: node.price * node.volume
        }));
        setData({ ...sampleData, nodes: nodesWithTotalValue });
      }, 1000); // Simulate a 1-second delay
    };

    fetchData();
  }, []);

  return (
    <div className="connectivity-dashboard-container">
      <div className="sidebar">
        <div className="control-group">
          <label htmlFor="threshold">
            Significance Threshold: {threshold.toFixed(2)}
          </label>
          <input
            type="range"
            id="threshold"
            min="0"
            max="1"
            step="0.05"
            value={threshold}
            onChange={(e) => setThreshold(parseFloat(e.target.value))}
          />
        </div>

        <div className="control-group">
          <label htmlFor="layout">Layout:</label>
          <select
            id="layout"
            value={layout}
            onChange={(e) => setLayout(e.target.value)}
          >
            <option value="force">Force-Directed</option>
            <option value="circular">Circular</option>
            <option value="grid">Grid</option>
            <option value="hierarchical">Hierarchical</option>
          </select>
        </div>
      </div>

      <div className="main-content">
        <div className="graph-container">
          <D3ConnectivityGraph
            data={filteredData}
            threshold={threshold}
            layout={layout}
          />
        </div>

        <div className="data-tables-container">
          <DataTables
            nodes={data.nodes}
            links={data.links}
            activeTab={activeTab}
            onTabChange={setActiveTab}
            onFilterChange={handleFilterChange}
            threshold={threshold}
          />
        </div>
      </div>
    </div>
  );
};

export default ConnectivityDashboard;
